<template>
    <div class="couture">
        <headComp></headComp>
        <breadComp></breadComp>

        <div class="centerWrapper">
            <div class="banner">
                <el-carousel>
                    <el-carousel-item v-for="item in coutureBannerList" :key="item.sort" @click.native="clickBannerImg(item.linkUrl)">
                        <!-- <img src="../assets/imgs/place19.png"/> -->
                        <img :style="{'cursor':item.linkUrl?'pointer':''}" :src="item.bannerImgUrl"/>
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="kinds">
                <div :class="{'item':1,'active':chosen==i}" v-for="(item,i) in coutrueTypes" :key="i" @click="clickType(item,i)">
                    <div class="text">{{item.goodsTypeName}}</div>
                    <!-- <img :src="item.img?'/imgs/'+item.img:require('../assets/imgs/place20.png')"/> -->
                    <!-- <img :src="item.cover?item.cover:(item.img?'/imgs/'+item.img:require('../assets/imgs/place20.png'))"/> -->
                    <img :src="item.cover?item.cover:(item.img?'/imgs/'+item.img:'')"/>
                </div>
            </div>
            <div class="showArea">
                <div class="kind">
                    <div class="show">
                        <div class="top">
                            <div class="kindName">
                                <span>沙发</span>
                                <span class="eng">Sofa</span>
                            </div>
                            <div class="moreChoice">
                                <div class="tabs">
                                    <div class="item" :class="{'active':sofaMaterialValue==item.dictValue}" v-for="(item,i) in sofaMaterialList" :key="i" @click="clickSofaMaterial(item)">{{item.dictLabel}}</div>
                                </div>
                                <div class="more">
                                    <!-- <img src="../assets/imgs/more1.png"/> -->
                                    更多
						            <i class="el-icon-arrow-right"></i>
                                </div>
                            </div>
                        </div>
                        <div class="area">
                            <div class="item" v-for="(item,i) in sofaGoodsList" :key="i">
                                <div class="pic">
                                    <img :src="item.cover?item.cover:require('../assets/imgs/place21.png')"/>
                                    <div class="text">{{item.brandName}}</div>
                                </div>
                                <div class="name">{{item.name}}</div>
                                <div class="price"><span>￥</span>{{item.ourShopPrice}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="relaBrand" v-if="false">
                        <div class="text">相关品牌</div>
                        <div class="list">
                            <div class="item" v-for="(item,i) in sofaBrandList" :key="i">
                                <!-- <img :src="item.brandLogo?item.brandLogo:require('../assets/imgs/place22.png')"/> -->
                                <img :src="item.brandLogo?item.brandLogo:(item.cover?item.cover:item.defaultUrl)"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="kind">
                    <div class="show">
                        <div class="top">
                            <div class="kindName">
                                <span>茶几/电视柜</span>
                                <span class="eng">cabinets</span>
                            </div>
                            <div class="moreChoice">
                                <div class="tabs">
                                    <div class="item" :class="{'active':teaOrTvValue==item.dictValue}" v-for="(item,i) in tableTypeList" :key="i" @click="clickTeaOrTV(item)">{{item.dictLabel}}</div>
                                </div>
                                <div class="more">
                                    <!-- <img src="../assets/imgs/more1.png"/> -->
                                    更多
						            <i class="el-icon-arrow-right"></i>
                                </div>
                            </div>
                        </div>
                        <div class="area">
                            <div class="item" v-for="(item,i) in teaOrTVGoodsList" :key="i">
                                <div class="pic">
                                    <img :src="item.cover?item.cover:require('../assets/imgs/place21.png')"/>
                                    <div class="text">{{item.brandName}}</div>
                                </div>
                                <div class="name">{{item.name}}</div>
                                <div class="price"><span>￥</span>{{item.ourShopPrice}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="relaBrand" v-if="false">
                        <div class="text">相关品牌</div>
                        <div class="list">
                            <div class="item" v-for="(item,i) in teaOrTVBrandList" :key="i">
                                <!-- <img :src="item.brandLogo?item.brandLogo:require('../assets/imgs/place22.png')"/> -->
                                <img :src="item.brandLogo?item.brandLogo:(item.cover?item.cover:item.defaultUrl)"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="kind">
                    <div class="show">
                        <div class="top">
                            <div class="kindName">
                                <span>床</span>
                                <span class="eng">Bed</span>
                            </div>
                            <div class="moreChoice">
                                <div class="tabs">
                                    <div class="item" :class="{'active':bedMaterialValue==item.dictValue}" v-for="(item,i) in bedMaterialList" :key="i" @click="clickBedMaterial(item)">{{item.dictLabel}}</div>
                                </div>
                                <div class="more">
                                    <!-- <img src="../assets/imgs/more1.png"/> -->
                                    更多
						            <i class="el-icon-arrow-right"></i>
                                </div>
                            </div>
                        </div>
                        <div class="area">
                            <div class="item" v-for="(item,i) in bedGoodsList" :key="i" @click="clickGoods(item)">
                                <div class="pic">
                                    <img :src="item.cover?item.cover:require('../assets/imgs/place21.png')"/>
                                    <div class="text">{{item.brandName}}</div>
                                </div>
                                <div class="name">{{item.name}}</div>
                                <div class="price"><span>￥</span>{{item.ourShopPrice}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="relaBrand" v-if="false">
                        <div class="text">相关品牌</div>
                        <div class="list">
                            <div class="item" v-for="(item,i) in bedBrandList" :key="i">
                                <!-- <img :src="item.brandLogo?item.brandLogo:require('../assets/imgs/place22.png')"/> -->
                                <img :src="item.brandLogo?item.brandLogo:(item.cover?item.cover:item.defaultUrl)"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <footerComp></footerComp>
    </div>
</template>

<script>
import headComp from "@/components/headComp.vue"
import footerComp from "@/components/footerComp.vue"
import breadComp from "@/components/breadComp.vue"
import {getDictTypeList,getCoutureGoodsList,getSofaBrandList,getTeaOrTVBrandList,getBedBrandList,
getCoutureGoodsTypeCover,getWebsiteSetting} from "@/axios/api"
export default {
    components: {
        headComp,footerComp,breadComp
    },
    data(){
        return{
            coutrueTypes:[
                {
                    goodsTypeName:"沙发",
                    goodsTypeId:"1",
                    img:"",
                    cover:"",
                },
                {
                    goodsTypeName:"电视柜",
                    goodsTypeId:"1",
                    img:"couture2.png",
                    cover:""
                },
                {
                    goodsTypeName:"茶几",
                    goodsTypeId:"1",
                    img:"couture3.png",
                    cover:"",
                },
                {
                    goodsTypeName:"书桌",
                    goodsTypeId:"1",
                    img:"couture4.png",
                    cover:"",
                },
                {
                    goodsTypeName:"床",
                    goodsTypeId:"1",
                    img:"couture5.png",
                    cover:"",
                },
                {
                    goodsTypeName:"餐桌",
                    goodsTypeId:"1",
                    img:"couture6.png",
                    cover:"",
                },
                {
                    goodsTypeName:"书柜",
                    goodsTypeId:"1",
                    img:"couture7.png",
                    cover:"",
                },
            ],
            chosen:0,
            sofaMaterialList:[],//沙发材质
            sofaMaterialValue:"",
            sofaGoodsList:[],//沙发列表
            sofaBrandList:[],//沙发相关品牌列表
            tableTypeList:[],//茶几电视柜类别
            teaOrTvValue:"",
            teaOrTVGoodsList:[],//茶几/电视柜商品
            teaOrTVBrandList:[],//茶几/电视柜相关品牌
            bedMaterialList:[],//床榻材质
            bedMaterialValue:"",
            bedGoodsList:[],//床榻商品
            bedBrandList:[],//床榻相关品牌
            coutureBannerList:[],//banner列表
        }
    },
    methods: {
        // 查询网站配置
		gainWebsiteSetting(){
			getWebsiteSetting({
				host:location.host,
				// host:"www.i-dian.com",
			}).then(res=>{
                // console.log("网站配置",res);
                if(res.code==200){
                    if(res.data.websiteLogo){
                        this.websiteLogo=res.data.websiteLogo;
                    }
                    if(res.data.bannerPlaceList){
                        res.data.bannerPlaceList.forEach(item=>{
                            if(item.bannerPlace==2){
                                this.coutureBannerList=item.bannerSettingList
                            }
                        })
                    }
                }
			})
		},
        clickBannerImg(url){
			// // console.log(url)
			if(url){
				window.open(url,'_blank')
			}
		},
        // 获取分类信息
        gainTypeCover(){
            getCoutureGoodsTypeCover().then(res=>{
                // console.log("分类信息",res);
                if(res.code==200){
                    this.coutrueTypes=res.data;
                }
            })
        },
        // 获取沙发材质
        gainSofaMaterial(){
            getDictTypeList({
                dictType:"GoodsSofaMaterial"
            }).then(res=>{
                // console.log("沙发材质",res);
                if(res.code==200){
                    this.sofaMaterialList=res.data;
                    this.sofaMaterialValue=res.data[0].dictValue;
                }
                this.gainSofaGoods();
                this.gainSofaBrand();
            })
        },
        // 点击沙发材质
        clickSofaMaterial(item){
            this.sofaMaterialValue=item.dictValue;
            this.gainSofaGoods();
            this.gainSofaBrand();
        },
        // 获取沙发商品
        gainSofaGoods(){
            getCoutureGoodsList({
                page:1,
                size:8,
                goodsTypes:"1",
                sofaMaterial:this.sofaMaterialValue,
            }).then(res=>{
                // console.log("沙发商品",res);
                if(res.code==200){
                    this.sofaGoodsList=res.data.rows;
                }
            })
        },
        // 获取沙发相关品牌
        gainSofaBrand(){
            getSofaBrandList({
                typeId:1,
                sofaMaterial:this.sofaMaterialValue
            }).then(res=>{
                // console.log("相关品牌--沙发",res);
                if(res.code==200){
                    this.sofaBrandList=res.data;
                }
            })
        },
        // 获取风格
        gainGoodsType(){
            getDictTypeList({
                dictType:"GoodsStyle"
            }).then(res=>{
                // console.log("风格",res);
                if(res.code==200){
                    // var arr=res.data.filter(item=>{
                    //     if(item.dictLabel.indexOf("茶几")!=-1||item.dictLabel.indexOf("电视")!=-1||item.dictLabel.indexOf("几")!=-1){
                    //         return item
                    //     }
                    // })
                    // // // console.log(arr)
                    // this.tableTypeList=arr;
                    this.tableTypeList=res.data.splice(0,6)
                    this.teaOrTvValue=this.tableTypeList[0].dictValue
                }
                this.gainTeaOrTV();
                this.gainTeaOrTVBrand();
            })
        },
        // 点击茶几或电视柜
        clickTeaOrTV(item){
            this.teaOrTvValue=item.dictValue;
            this.gainTeaOrTV();
            this.gainTeaOrTVBrand();
        },
        // 获取茶几/电视柜商品
        gainTeaOrTV(){
            getCoutureGoodsList({
                page:1,
                size:8,
                goodsTypes:"2,85",
                style:this.teaOrTvValue
            }).then(res=>{
                // console.log("茶几/电视柜商品",res);
                if(res.code==200){
                    this.teaOrTVGoodsList=res.data.rows;
                }
            })
        },
        // 获取茶几/电视柜相关品牌
        gainTeaOrTVBrand(){
            getTeaOrTVBrandList({
                typeIds:'2,85',
                style:this.teaOrTvValue
            }).then(res=>{
                // console.log("茶几/电视柜相关品牌",res);
                if(res.code==200){
                    this.teaOrTVBrandList=res.data
                }
            })
        },
        // 获取床榻材质
        gainBedMaterial(){
            getDictTypeList({
                dictType:"GoodsBedMaterial"
            }).then(res=>{
                // console.log("床榻材质",res);
                if(res.code==200){
                    this.bedMaterialList=res.data;
                    this.bedMaterialValue=res.data[0].dictValue;
                }
                this.gainBedGoods();
                this.gainBedBrand();
            })
        },
        // 点击床榻材质
        clickBedMaterial(item){
            this.bedMaterialValue=item.dictValue;
            this.gainBedGoods();
            this.gainBedBrand();
        },
        // 获取床榻商品
        gainBedGoods(){
            getCoutureGoodsList({
                page:1,
                size:4,
                goodsTypes:"10",
                bedMaterial:this.bedMaterialValue
            }).then(res=>{
                // console.log("床榻商品",res);
                if(res.code==200){
                    this.bedGoodsList=res.data.rows;
                }
            })
        },
        // 获取床榻相关品牌
        gainBedBrand(){
            getBedBrandList({
                typeId:"10",
                bedMaterial:this.bedMaterialValue
            }).then(res=>{
                // console.log("床榻相关品牌",res);
                if(res.code==200){
                    this.bedBrandList=res.data
                }
            })
        },
        // 点击商品
        clickGoods(item){
            this.$router.push({
                path:"/furnitureDetail",
                query:{goodsId:item.goodsId}
            })
        },
        // 点击分类
        clickType(item,i){
            this.chosen=i;
            // // console.log(item);
            sessionStorage.removeItem('searchKeyword')
            sessionStorage.goodsTypeId=item.goodsTypeId;
            this.$router.push({
                path:"/merchandise",
                query:{fromCouture:true}
            })
        }
    },
    created() {
        this.gainWebsiteSetting();
        this.gainTypeCover();
        this.gainSofaMaterial();
        this.gainGoodsType();
        this.gainBedMaterial();
    },
}
</script>

<style lang="less" scoped>
.couture{
    .banner{
        margin-top: 50px;
        width: 100%;
        height: 630px;
        .el-carousel{
            height: 100%;
            /deep/.el-carousel__container{
                height: 100%;
            }
            /deep/.el-carousel__item{
                img{
                    height: 100%;
                    width: 100%;
                    // object-fit: cover;
                }
            }
        }
    }
    .kinds{
        margin-top: 43px;
        display: grid;
        grid-template-columns: 585px 309px 224px 224px;
        grid-template-rows: 199px 199px;
        grid-row-gap: 17px;
        justify-content: space-between;
        .item{
            background: rgba(240, 240, 240, 1);
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border-radius: 6px;
            img{
                // max-width: 80%;
                // width: 80%;
                // height: 80%;
                // object-fit: contain;
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 6px;
            }
            .text{
                position: absolute;
                top: 0;
                right: 0;
                background: linear-gradient(90deg, rgba(163, 138, 127, 0.4) 0%, rgba(163, 138, 127, 0.47) 52.7%, rgba(163, 138, 127, 0.4) 100%);
                padding: 5px 7px;
                font-size: 24px;
                font-weight: 500;
                color: rgba(255, 255, 255, 1);
                min-width: 95px;
                box-sizing: border-box;
                text-align: center;
                border-top-right-radius: 6px;
            }
            &.active{
                background: rgba(158, 110, 62, 0.3);
            }
            &:nth-child(1){
                grid-row-start: 1;
                grid-row-end: 3;
            }
        }
    }
    .showArea{
        margin-top: 60px;
        .kind{
            .show{
                .top{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 30px;
                    .kindName{
                        width: 354px;
                        // height: 15px;
                        background-image:url("../assets/imgs/effect3.png");
                        background-position: bottom;
                        background-repeat: no-repeat;
                        font-size: 20px;
                        font-weight: 400;
                        color: #333;
                        .eng{
                            font-size: 20px;
                            font-weight: 700;
                            color: rgba(135, 104, 85, 1);
                            margin-left: 2px;
                        }
                    }
                    .moreChoice{
                        display: flex;
                        align-items: center;
                        .tabs{
                            display: flex;
                            align-items: center;
                            .item{
                                cursor: pointer;
                                font-size: 16px;
                                font-weight: 700;
                                color: rgba(56, 56, 56, 1);
                                margin-right: 30px;
                                &:hover{
                                    color: #257e5d;
                                }
                                &.active{
                                    color: #257e5d;
                                    border-bottom: 2px solid #257e5d;
                                }
                            }
                        }
                        .more{
                            cursor: pointer;
                            width: 73px;
                            height: 32px;
                            border-radius: 24px;
                            background: rgba(229, 229, 229, 1);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 18px;
                            font-weight: 500;
                            color: #333;
                            padding-left: 5px;
					        padding-bottom: 1px;
                            i{
                                // margin-left: 4px;
                                // margin-top: 2px;
                            }
                            &:hover{
                                background: rgba(225, 242, 236, 1);
                                color: rgba(37, 126, 93, 1);
                            }
                        }
                    }
                }
                .area{
                    margin-top: 30px;
                    display: flex;
                    flex-wrap: wrap;
                    .item{
                        width: 312px;
                        // margin-right: 55px;
                        margin-right: 74px;
                        margin-bottom: 30px;
                        &:nth-child(4n){
                            margin-right: 0;
                        }
                        .pic{
                            position: relative;
                            img{
                                width: 100%;
                                height: 233px;
                                border-radius: 10px;
                            }
                            .text{
                                padding: 0 8px;
                                height: 31px;
                                display: flex;
                                align-items: center;
                                box-sizing: border-box;
                                max-width: calc(100% - 40px);
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                position: absolute;
                                bottom: -15px;
                                left: 20px;
                                border-radius: 4px;
                                background: linear-gradient(90deg, rgba(188, 151, 115, 1) 0%, rgba(224, 183, 132, 1) 100%);
                                font-size: 15px;
                                font-weight: 700;
                                color: rgba(255, 255, 255, 1);
                            }
                        }
                        .name{
                            margin: 0 20px;
                            margin-top: 27px;
                            font-size: 17px;
                            font-weight: 700;
                            color: #333;
                        }
                        .price{
                            margin: 0 20px;
                            margin-top: 5px;
                            font-size: 16px;
                            font-weight: 700;
                            color: #333;
                            &>span{
                                font-size: 13px;
                            }
                        }
                    }
                }
            }
            .relaBrand{
                margin-top: 40px;
                .text{
                    font-size: 20px;
                    font-weight: 500;
                    color: #333;
                }
                .list{
                    margin-top: 30px;
                    display: flex;
                    flex-wrap: wrap;
                    .item{
                        width: 343px;
                        height: 100px;
                        // margin-right: 20px;
                        margin-right: 32px;
                        margin-bottom: 23px;
                        &:nth-child(4n){
                            margin-right: 0;
                        }
                        img{
                            width: 100%;
                            height: 100%;
                            border-radius: 6px;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
        
    }
}
</style>