<template>
    <div class="breadComp centerWrapper" v-if="show">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item v-if="isHigh">高定</el-breadcrumb-item>
            <el-breadcrumb-item v-if="!isGoodsDetail&&clearFrom!=2">{{clearFrom==1?'购物车':pageName}}</el-breadcrumb-item>
            <span v-if="isGoodsDetail">
                <el-breadcrumb-item @click.native="clickUpType">{{breadGoodsInfo.upGoodsTypeName}}</el-breadcrumb-item>
                <el-breadcrumb-item @click.native="clickType">{{breadGoodsInfo.goodsTypeName}}</el-breadcrumb-item>
                <el-breadcrumb-item @click.native="clickBrand">{{breadGoodsInfo.brandName}}</el-breadcrumb-item>
                <el-breadcrumb-item>{{breadGoodsInfo.sn}}</el-breadcrumb-item>
            </span>
            <el-breadcrumb-item v-if="$route.name=='wishMatch_clear'">{{(clearFrom==1||clearFrom==2)?'':wishMatchCombo}} 结算</el-breadcrumb-item>
        </el-breadcrumb>
    </div>
</template>

<script>
export default {
    props:['wishMatchCombo','breadGoodsInfo','clearFrom'],
    data(){
        return{
            show:false,
            pageName:"",
            isHigh:false,//是否高定商品页面
            isGoodsDetail:false,//是否商品详情页面
        }
    },
    created() {
        // console.log(this.$route)
        if(this.$route.name=="Home"){
            this.show=false
        }else{
            this.show=true;
        }
        if(this.$route.query.fromCouture&&sessionStorage.goodsTypeId){
            this.isHigh=true;
        }
        if(this.$route.path=="/furnitureDetail"){
            this.isGoodsDetail=true;
        }
        if(this.$route.query.fromHome&&sessionStorage.home_typeId){
            this.pageName=sessionStorage.home_typeName
        }else{
            this.pageName=this.$route.meta.pageName
        }
    },
    methods: {
        clickUpType(){
            sessionStorage.home_typeId=this.breadGoodsInfo.upGoodsTypeId;
            sessionStorage.home_typeName=this.breadGoodsInfo.upGoodsTypeName;
            sessionStorage.removeItem('brandId')
            this.$router.push({
                path:"/merchandise",
                query:{fromHome:true,t3:this.breadGoodsInfo.upGoodsTypeId}
            })
        },
        clickType(){
            sessionStorage.home_typeId=this.breadGoodsInfo.goodsTypeId;
            sessionStorage.home_typeName=this.breadGoodsInfo.goodsTypeName;
            sessionStorage.removeItem('brandId')
            this.$router.push({
                path:"/merchandise",
                query:{fromHome:true,t3:this.breadGoodsInfo.upGoodsTypeId}
            })
        },
        clickBrand(){
            sessionStorage.brandId=this.breadGoodsInfo.brandId;
            sessionStorage.removeItem('home_typeId')
            this.$router.push({
                path:"/merchandise",
                query:{fromBrand:true,pa:"bi_"+this.breadGoodsInfo.brandId}
            })
        },
    },
    watch:{
        '$router'(n,o){
            // console.log(n)
        }
    }
}
</script>

<style lang="less" scoped>
.breadComp{
    // margin-top: 20px;
    .el-breadcrumb{
        /deep/.el-breadcrumb__item{
            font-size: 14px;
            font-weight: 500;
            color: rgba(0, 0, 0, 1);
            cursor: pointer;
            &:hover{
                .el-breadcrumb__inner{
                    color: #257e5d;
                }
            }
            .el-breadcrumb__inner{
                color: rgba(0, 0, 0, 1);
            }
        }
    }
}
</style>